<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              년월
            </div>
            <ul class="content">
              <li class="item date">
                <input-date
                    ref="searchSelectVisitor"
                    v-model="searchFilter.yyyyMM"
                    format="YYYY-MM"
                    depth="Year"
                />
              </li>
            </ul>
            <div class="title">
              수신처
            </div>
            <ul class="content">
              <li class="item date" style="width: 192px">
                <ejs-dropdownlist v-model="searchFilter.recptnComp"
                                  :dataSource="commonCodeOptions.recptnComp"
                                  :fields="commonCodeOptions.fields"
                                  :allowFiltering="false"
                                  cssClass="lookup-condition-dropdown" />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">코스</div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect ref="course" v-model="searchFilter.courseList"
                                 :dataSource="commonCodeOptions.course" :fields="commonCodeOptions.fields"
                                 :allowFiltering="false" cssClass="lookup-condition-multiselect" width="200"/>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              v-on:click.native="getReportViewData"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <ul class="lookup-button">
          <li class="print">
            <erp-button
                button-div="FILE"
                @click.native="onPrintClick"
            >
              인쇄
            </erp-button>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="false"/>
    </div>
  </div>
</template>

<script>

import InputDate from '@/components/common/datetime/InputDate';
import { getFormattedTelNumber } from "@/utils/string";
import {commonCodesGetCommonCode} from "@/utils/commonCodes";
import moment from "moment";
import ReportView from '@/components/common/report/ReportView';
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {commonCodesGetBizMstInfo} from "@/utils/commonCodes";
import ErpButton from "@/components/button/ErpButton.vue";
import {
  DATE_FORMAT_YYYY_MM,
} from '@/utils/date';
import {mapGetters} from "vuex";
import {getBizInfo} from "@/api/bizCodeManagement";
import {getCurrentBizCode} from "@/utils/pageKeyUtil";
import {numberWithCommas} from "@/utils/number";

export default {
  name: "MonthlyVisitorStatus",
  components: {
    InputDate,
    ReportView,
    ErpButton
  },
  async created() {
  },
  computed: {
    ...mapGetters(["username"]),
  },
  async mounted() {
    await this.getReportViewData();
  },
  data() {
    return {
      //조회 필드
      searchFilter: {
        yyyyMM:  moment().format(DATE_FORMAT_YYYY_MM),
        recptnComp: "GOLF2",
        courseList: [2,3],
      },

      //드롭다운 코드
      commonCodeOptions: {
        recptnComp: commonCodesGetCommonCode("REPORT_DEST",false),
        course: commonCodesGetCommonCode("COURSE_CODE",false),
        fields: { text: "comName", value: "comCode" },
      },
    };
  },
  methods: {
    async getReportViewData() {
      let res = await GolfErpAPI.fetchMonthlyVisitorStatus({
        yyyyMM: this.searchFilter.yyyyMM.replace("-",""),
        courseList: this.searchFilter.courseList.length>0 ? this.searchFilter.courseList:undefined
      });
      if(res.length==0){
        return;
      }
      const bizCode = getCurrentBizCode();
      const {value: {tsBizMstList},} = await getBizInfo('bizCode', bizCode);
      const bizInfo = tsBizMstList.find(item => item.bizCode === bizCode);

      const reception     = this.commonCodeOptions.recptnComp.find(({comCode}) => comCode == this.searchFilter.recptnComp).comName;
      const receptionTels = this.commonCodeOptions.recptnComp.find(({comCode}) => comCode == this.searchFilter.recptnComp )
          .comCodeAttrbList.map(({attrb,attrbName})=>{
            return {attrb,attrbName};
          });

      const reportData = {
        reportJson: {
          data: {},
          yyyyMM: `${this.searchFilter.yyyyMM.substring(0,4)}년 ( ${this.searchFilter.yyyyMM.substring(5)} )월분`,
          reception:  `수신: ${reception}`,
          ccName: `○ 골프장명: (  ${bizInfo.ccName}  )`,
          rprsntvName: `○ 대표이사 (  ${bizInfo.rprsntvName}  ) (인)`,
          bizName: `○ 법 인 명: (  ${bizInfo.bizName}  )`,
          totBsnDay: `${res[0].totBsnDay} 일`,
          visit18hPer: `${numberWithCommas(res[0].vist18hPer)} 명`,
          visit9hPer:  '', //`${numberWithCommas(res[0].vist9hAditPer)} 명`,
          visitTotPer: `${numberWithCommas(res[0].vist18hPer)} 명`,
          sendDeptName: "총 무 과",
          sendRank : "차 장",
          sendName : "김 황 철", // this.username ,
          sendTel: "062) 605-3114",
          sendDeadline : "매월 5일",
          //sendMethod: "팩스 031) 624-5868",
          //recptnName: "이수진 대리 031) 781-6090",
          sendMethod: `팩스 ${receptionTels[0]?.attrbName}`,
          recptnName: `이수진 과장 ${receptionTels[1]?.attrbName}`,
          uniqueCode: this.$options.name,
        }
      };

       //console.log(reportData, result);
       //console.log(JSON.stringify(reportData));

      await this.$refs.reportViewComponent.postReport(
          reportData,
          `${this.$options.name}`,
      );
    },
    onPrintClick() {
      this.$refs.reportViewComponent.printReport();
    }
  }

};
</script>

